import React, { Suspense } from "react";
import { Route, Routes } from "react-router-dom";
import "./scss/style.scss";

const loading = (
  <div className="pt-3 text-center">
    <div className="sk-spinner sk-spinner-pulse"></div>
  </div>
);

// Containers
const DefaultLayout = React.lazy(() => import("./layout/DefaultLayout"));

// Pages
const Login = React.lazy(() => import("./views/pages/login/Login"));
const ForgotPassword = React.lazy(() => import("./views/pages/ResetPassword/forgotPassword"));
const VerifyToken = React.lazy(() => import("./views/pages/ResetPassword/verifyToken"));
const SetNewPassword = React.lazy(() => import("./views/pages/ResetPassword/setNewPassword"));
const PasswordChanged = React.lazy(() => import("./views/pages/ResetPassword/passwordChanged"));
const About = React.lazy(() => import("./views/pages/StaticContent/About"));
const Contact = React.lazy(() => import("./views/pages/StaticContent/Contact"));
const Support = React.lazy(() => import("./views/pages/StaticContent/Support"));
const TermsAndConditions = React.lazy(() => import("./views/pages/StaticContent/TermsAndConditions"));
const Page404 = React.lazy(() => import("./views/pages/page404/Page404"));
const Page500 = React.lazy(() => import("./views/pages/page500/Page500"));

const App = () => {
  return (
    <Suspense fallback={loading}>
      <Routes>
        <Route exact path="/login" name="Login Page" element={<Login />} />
        <Route exact path="/forgot-password" name="Password Reset Page" element={<ForgotPassword />} />
        <Route exact path="/verify-token" name="Verify Token" element={<VerifyToken />} />
        <Route exact path="/set-new-password" name="Set New Password" element={<SetNewPassword />} />
        <Route exact path="/password-change-success" name="Password Changed" element={<PasswordChanged />} />
        <Route exact path="/cms/about-us" name="About Page" element={<About />} />
        <Route exact path="/cms/contact" name="Privacy Policy" element={<Contact />} />
        <Route exact path="/cms/support" name="SupportUs" element={<Support />} />
        <Route exact path="/cms/terms-conditions" name="Terms And Conditions" element={<TermsAndConditions />} />
        <Route exact path="/404" name="Page 404" element={<Page404 />} />
        <Route exact path="/500" name="Page 500" element={<Page500 />} />
        <Route path="*" name="Home" element={<DefaultLayout />} />
      </Routes>
    </Suspense>
  );
};

export default App;
